import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [],
  providers: [AuthService],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: []
})
export class AuthModule { }
