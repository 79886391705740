import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';
import { Idocument } from './idocument';
import { CompanyService } from './companies/company.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  public data$ = new Subject();
  public newCompany$ = new Subject();

  data: any = {
    id: '',
    company: '',
    url: '',
    owner: '',
    address: '',
    place: '',
    email: '',
    phone: '',
    fax: '',
    taxIDSteuernummer: '',
    taxIDUStLdNr: '',
    includeLogo: false,
    uriEncodedBase64Logo: '',
    hasHtmlExtension: false,
    company_type:''
  };

  constructor(private companyService: CompanyService) {
    // this.setDocument(data);
    this.companyService.company$.subscribe({
      next: (data:any)=>{
        this.data = data;
        console.log('called it will set data')
        console.log(data)
        this.setDocument(data);
        this.data$.next(data);
      }
    })
  }

  setDocument(data: Idocument){
    this.data$.next(data);
  }  

  storeCompany(data: Idocument){
    this.newCompany$.next(data);    
  }

  removeCompany(data: Idocument){
    // this.data$.next(data);
  }
}
