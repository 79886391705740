<div>

  <ul class="list-unstyled ps-0 scrollable">

    <!-- <h3 *ngIf="companyMessageHelp">{{companyMessageHelp}}</h3> -->
    <li *ngIf="filteredCompanies.length === 0">

      <!-- Loader 2 -->

      <svg version="1.1" id="L2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" class="loader">
        <circle fill="none" stroke="#000" stroke-width="4" stroke-miterlimit="10" cx="50" cy="50" r="48" />
        <line fill="none" stroke-linecap="round" stroke="#000" stroke-width="4" stroke-miterlimit="10" x1="50" y1="50"
          x2="85" y2="50.5">
          <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 50" to="360 50 50"
            repeatCount="indefinite" />
        </line>
        <line fill="none" stroke-linecap="round" stroke="#000" stroke-width="4" stroke-miterlimit="10" x1="50" y1="50"
          x2="49.5" y2="74">
          <animateTransform attributeName="transform" dur="15s" type="rotate" from="0 50 50" to="360 50 50"
            repeatCount="indefinite" />
        </line>
      </svg>


      <!-- No companies found, try adding one! -->

    </li>

    <li class="text-center" *ngIf="filteredCompanies.length !== 0">
      Total entries: {{allCompanies.length}}
    </li>
    <!-- <li *ngIf="filteredCompanies.length !== 0">
      <input type="text" name="search" class="form-control" placeholder="Company search">
    </li> -->
    <li class="border-bottom" *ngFor="let company of allCompanies; index as i;">
      <button class="btn btn-toggle align-items-center rounded me-auto maxchar" (click)="setCompany(company)">
        {{company.url.replace('http://','').replace('https://','').slice(0, 18)}}{{company.url.replace('http://','').replace('https://','').length>18?'...':''}}
      </button>

      <button class="btn btn-toggle align-items-center rounded float-end" (click)="removeCompany(company)">
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" height="16px" focusable="false"
          aria-hidden="true" viewBox="0 0 24 24" data-testid="DeleteForeverIcon">
          <path
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12 1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z">
          </path>
        </svg>
      </button>
    </li>
  </ul>
</div>