<div *ngIf="appTmp">
    <router-outlet></router-outlet>
</div>

<div class="container pt-2 bg-white mt-3 pb-2 box-shadow" *ngIf="!appTmp">
    <div class="row align-items-center">
        <div class="col-12 text-center pt-5 pb-5">
        <h1>Please update your application to the latest version!</h1>
        </div>
    </div>
</div>