import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class CfSecurityGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
            
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const user = this.authService.userValue;
        if (
            user && user.team_role === 'admin'
            ) { // || state.url.includes('/login')
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
        this.router.navigate(['/']);
        return false;
    }
}