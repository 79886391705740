import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Idocument } from '../idocument';
import { DocumentService } from '../document.service';
import { CompanyService } from './company.service';


@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  allCompanies: Idocument[] | any = [];
  filteredCompanies: Idocument[] | any = [];


  @Output('company') companyEmmiter = new EventEmitter<Idocument>();

  constructor(private documentS: DocumentService, private http: HttpClient, private companyService: CompanyService) {
    

    this.documentS.newCompany$.subscribe({
      next: newCompany => {
        
        // let params = new HttpParams()
        //   .set('jwt', this.credentials.jwt)
        //   .set('data', JSON.stringify(c))
        //   .set('new-company', true);
// console.log(newCompany);
        // this.http.post('/api/v1/api/index.php', params).subscribe({
          this.http.post('/api/v1/companies', newCompany).subscribe({
          next: (result: any) => {

            // if (result.hasOwnProperty('message')) {
            //   this.companyMessageHelp = result.message;
            // } else {
            // }
            // console.log(result);
            // this.companies = result;
            // if(typeof result.id === undefined){
              
            // }
            this.allCompanies.push(result);            
            this.allCompanies = this.sortAlphabetically(this.allCompanies);
            // this.filteredCompanies.push(result);
          },
          error: e => {
            console.error(e)
          }
        });


      }
    });    
  }

  sortAlphabetically(companies:Idocument[]){

    // I need to sort the company objects by url property here
    companies.sort((a, b) => {
      const urlA = a.url.toLowerCase();
      const urlB = b.url.toLowerCase();
  
      if (urlA < urlB) {
        return -1;
      }
      if (urlA > urlB) {
        return 1;
      }
      return 0;
    });
    
    return companies;
  }


  async ngOnInit() {    
    this.allCompanies = await this.companyService.loadCompanies();

    this.allCompanies = this.sortAlphabetically(this.allCompanies);
    // this.filteredCompanies = this.allCompanies.slice(0, 10);
    this.filteredCompanies = this.allCompanies;
  }

  
  setCompany(company: Idocument) {
    // Set data to preview
    // this.documentS.data$.next(company);
    // this.company.emit(company);

    // this.data$.next(data);

    // console.log('we need to send this data to documents.component');
    // console.log(company);
    this.companyEmmiter.emit(company);
    // this.companyService.setCompany(company);
  }

  removeCompany(company: Idocument) {    
    if (confirm("Are you sure to delete " + company.company)) {


      // let params = new HttpParams()
      //   .set('jwt', this.credentials.jwt)
      //   .set('remove-company', true);

      // this.http.post('/api/index.php', params).subscribe({
      this.http.delete('/api/v1/companies/'+ company?.id).subscribe({
        next: (result: any) => {

          // if (result.hasOwnProperty('message')) {
          //   this.companyMessageHelp = result.message;
          // } else {
           
          // }
          // this.documentS.removeCompany(result);

          let index = this.allCompanies.findIndex(function(el:any){
            return el.id === result.id
          });

          this.allCompanies.splice(index,0);
        },
        error: e => {
          console.error(e)
        }
      });
    }

  }
}