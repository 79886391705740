import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Idocument } from '../idocument';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public company$ = new Subject();

  constructor(private http: HttpClient) { }

  
  setCompany(data: Idocument){
    this.company$.next(data);    
  }

  async getCompanyLogo(data: Idocument){
    return new Promise((resolve, reject)=>{
      try{

        this.http.get('/api/v1/companies/logo/'+data.id).subscribe({
          next: (result: any) => {  
            resolve(result);          
          },
          error: e => {
            // console.error(e)
            reject(e);
          }
        });
      }catch(e){

      }
    })
  }

  async loadCompanies() {
    return new Promise((resolve, reject) => {
      try{

        this.http.get('/api/v1/companies').subscribe({
          next: (result: any) => {
            resolve(result);
          },
          error: e => {
            // console.error(e)
            reject(e);
          }
        });
      }catch(e){

      }
    })
    
  }
}
