import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.guard';
import { TeamRoleTeamLeadGuard } from './auth/team-role-team-lead-guad.guard';
import { TeamRoleSupportGuard } from './auth/team-role-support-guad.guard';
import { AdminGuard } from './auth/admin-guad.guard';
import { KkEmailsGuard } from './auth/kk-emails-guad.guard';
import { CfSecurityGuard } from './auth/cf-security-guad.guard';

const loginModule = () => import('./login/login.module').then(m => m.LoginModule);
const homeModule = () => import('./home/home.module').then(m => m.HomeModule);
const documentsModulle = () => import('./documents/documents.module').then(m => m.DocumentsModule);
const domainHandlerModule = () => import('./domain-handler/domain-handler.module').then(m => m.DomainHandlerModule);
const smtpFixerModule = () => import('./smtp-fixer/smtp-fixer.module').then(m => m.FormFixerModule);
const wetransfersModule = () => import('./wetransfers/wetransfers.module').then(m => m.WetransfersModule);
const domainHandlerSupportModule = () => import('./domain-handler-support/domain-handler-support.module').then(m => m.DomainHandlerSupportModule);
const domainHandlerTeamLeadModule = () => import('./domain-handler-team-lead/domain-handler-team-lead.module').then(m => m.DomainHandlerTeamLeadModule);
const qrGeneratorModule = () => import('./qr-generator/qr-generator.module').then(m => m.QrGeneratorModule);

const kkEmailsSchedulerModule = () => import('./kk-emails-scheduler/kk-emails-scheduler.module').then(m => m.KkEmailsSchedulerModule);
const kkEmailTemplatesModule = () => import('./kk-email-templates/kk-email-templates.module').then(m => m.KkEmailTemplatesModule);
const kkEmailTemplaterModule = () => import('./kk-email-templater/kk-email-templater.module').then(m => m.KkEmailTemplaterModule) ;
const kkEmailSchedulesModule = () => import('./kk-email-schedules/kk-email-schedules.module').then(m => m.KkEmailSchedulesModule);
const kkEmailsModule = () => import('./kk-emails/kk-emails.module').then(m => m.KkEmailsModule);
const kkEmailsReplyModule = () => import('./kk-emails-reply/kk-emails-reply.module').then(m => m.KkEmailsReplyModule);

const kkEmailsAccountModule = () => import('./kk-email-accounts/kk-email-accounts.module').then(m => m.KkEmailAccountsModule);
const kkEmailsAccountCruModule = () => import('./kk-email-accounts-cru/kk-email-accounts-cru.module').then(m => m.KkEmailAccountsCruModule);
const cloudflareSecurityModule = () => import('./cloudflare-security/cloudflare-security.module').then(m => m.CloudflareSecurityModule)


const routes: Routes = [
  { path: '', loadChildren: homeModule, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: loginModule },
  { path: 'documents', loadChildren: documentsModulle, canActivate: [AuthGuard, TeamRoleTeamLeadGuard] },
  // { path: 'domain-handler', loadChildren: domainHandlerModule, canActivate: [AuthGuard] },
  { path: 'smtp-fixer', loadChildren: smtpFixerModule, canActivate: [AuthGuard] },
  // { path: 'site-hosts', loadChildren: siteHostsModule, canActivate: [AuthGuard] },
  // { path: 'wetransfers', loadChildren: wetransfersModule, canActivate: [AuthGuard] },
  { path: 'domain-handler-support', loadChildren: domainHandlerSupportModule, canActivate: [AuthGuard, TeamRoleSupportGuard] },
  { path: 'domain-handler-team-lead', loadChildren: domainHandlerTeamLeadModule, canActivate: [AuthGuard, TeamRoleTeamLeadGuard] },    
  { path: 'qr-generator', loadChildren: qrGeneratorModule },

  { path: 'kk-emails-scheduler', loadChildren: kkEmailsSchedulerModule, canActivate: [AuthGuard, KkEmailsGuard] },
  { path: 'kk-emails-scheduler/:id', loadChildren: kkEmailsSchedulerModule, canActivate: [AuthGuard, KkEmailsGuard] },
  { path: 'kk-email-templates', loadChildren: kkEmailTemplatesModule, canActivate: [AuthGuard, KkEmailsGuard] },
  // { path: 'kk-email-templates/:id', loadChildren: kkEmailTemplatesModule, canActivate: [AuthGuard, KkEmailsGuard] },
  { path: 'kk-email-schedules', loadChildren: kkEmailSchedulesModule, canActivate: [AuthGuard, KkEmailsGuard] },
  { path: 'kk-email-templater/:id', loadChildren: kkEmailTemplaterModule},
  { path: 'kk-emails', loadChildren: kkEmailsModule, canActivate: [AuthGuard, KkEmailsGuard] },
  { path: 'kk-emails-reply/:id', loadChildren: kkEmailsReplyModule, canActivate: [AuthGuard, KkEmailsGuard] },
  { path: 'kk-email-accounts', loadChildren: kkEmailsAccountModule, canActivate: [AuthGuard, KkEmailsGuard] }, //, canActivate: [AuthGuard, KkReportsGuard]   
  { path: 'kk-email-accounts-cru/:id', loadChildren: kkEmailsAccountCruModule, canActivate: [AuthGuard, KkEmailsGuard] },
  { path: 'cloudflare-security', loadChildren: cloudflareSecurityModule, canActivate: [AuthGuard, CfSecurityGuard] }, //, canActivate: [AuthGuard, KkReportsGuard] 
      
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
