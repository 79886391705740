import { Component} from '@angular/core';
// import { DocumentService } from './documents/document.service';
// import { Idocument } from './documents/idocument';
// import { CompanyService } from './documents/companies/company.service';
// import { AuthService } from './auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'document-generator';
 

  appTmp = false;
  
  constructor() {
    var dateNow = new Date();
    var dateExpire = new Date(2025, 12, 12);
    this.appTmp = dateNow < dateExpire;
  }

}