import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';

@Injectable()
export class AuthService {

  
  public userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(
      private router: Router,
      private http: HttpClient
  ) {
      this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
      this.user = this.userSubject.asObservable();
  }

  public get userValue() {
      this.setCurrentUser();
      return this.userSubject.value;
  }




  // public userSubject = new Subject();

  // constructor(private http: HttpClient) { } //public jwtHelperService: JwtHelperService, 
  // // ...
  // public isAuthenticated(): boolean {
  //   const token = sessionStorage.getItem('user');
  //   // Check whether the token is expired and return
  //   // true or false
  //   return !this.jwtHelperService.isTokenExpired(token);
  // }

  setUser(result: any) {

    sessionStorage.removeItem('user');
    sessionStorage.setItem('user', JSON.stringify(result));

    this.userSubject.next(result);
  }

  setCurrentUser() {
    /**
     * @todo check is session expired on remote host
     */
    let user = sessionStorage.getItem('user');

    /**
     * @todo if updated parse accordingly
     */
    user= user?JSON.parse(user):null;

    this.userSubject.next(user);
  }

  removeUser() {
    sessionStorage.removeItem('user');
    
    this.userSubject.next(null);
  }

  getToken() {
    let user: any = sessionStorage.getItem('user');

    if (user) {
      user = JSON.parse(user);
    }

    return user?.access_token;
  }

  // // User login
  login(credentials: any) {
    return new Promise((resolve, reject) => {

      this.http.post('/api/v1/auth/login', credentials
        // {
        //   user: {
        //     email: credentials.email,
        //     password: credentials.password,
        //   }
        // }
      ).subscribe({
        next: (result: any) => {

          // Form credentials
          credentials.email = '';
          credentials.password = '';

          this.setUser(result);

          resolve(result);
        },
        error: e => {
          console.error(e)
          reject(e);
        }
      });
    })

  }

  

//   login(credentials:any) {
//     return this.http.post<any>(`/api/v1/auth/login`, credentials)
//         .pipe(map(user => {
          
//           // Form credentials
//           credentials.email = '';
//           credentials.password = '';

//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             localStorage.setItem('user', JSON.stringify(user));
//             this.userSubject.next(user);
//             return user;
//         }));
// }


}